@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.recurring_availability {
  &__input {
    width: 100%;

    @media (min-width: 600px) {
      max-width: 280px;
    }
  }
}
